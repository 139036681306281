// TrasladosItem.js
import React from 'react';
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline, IoMdTime, IoMdCheckmarkCircle } from 'react-icons/io';
import { MdOutlineDirectionsCarFilled } from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { GiHealthNormal } from 'react-icons/gi';

const getStatusColor = status => {
  switch (status) {
    case 'Cancelado':
      return '#ee1325';
    case 'Finalizado':
      return '#6b6b6b';
      case 'Fallido':
        return '#663c05';      
    case 'Asignado':
      return '#2b9785';
    case 'Pre-Asignado':
      return '#1033ce';
    case 'Solicitado':
      return '#dd0973';
    case 'Programado':
      return '#9409f0';
    case 'Enterado':
      return '#0f790b';
      case 'Iniciado':
        return '#da5b07';      
    default:
      return '#e3f300';
  }
};
const TrasladosItem = ({ record, onSelect }) => {
    const statusColor  = getStatusColor(record.Generals.Status);

        const StatusIcon = () => {
            switch (record.Generals.Status) {
              case 'Cancelado':
                return <IoMdCloseCircleOutline color="black" />;
              case 'Finalizado':
                return <IoMdCloseCircleOutline color="black" />;
              case 'Fallido':
                return <IoMdCloseCircleOutline color="black" />;
              case 'Asignado':
                return <IoMdCheckmarkCircleOutline color="black" />;
              case 'Pre-Asignado':
                return <IoMdCheckmarkCircleOutline color="black" />;
              case 'Solicitado':
                return <IoMdCheckmarkCircleOutline color="black" />;
              case 'Programado':
                return <IoMdCheckmarkCircleOutline color="black" />;
              case 'Enterado':
                return <IoMdCheckmarkCircleOutline color="black" />;
              case 'Iniciado':
                return <IoMdCheckmarkCircleOutline color="black" />;                
              default:
                return <IoMdTime color="white" />;
            }
          };

            const ValidatedIcon = () => {
                if (record.Generals.Validated === "Validado") {
                  return <IoMdCheckmarkCircle size="3em" color={statusColor} />;
                }
                return null;
              };

    return (
      <div className="traslados-item" onClick={() => onSelect(record)}>
          <div className="traslados-item-id" style={{ backgroundColor: statusColor }}>
            CS-{record.id}
          </div>
          <div className="traslados-item-content">
            <div className="traslados-item-header">
              <div className="traslados-item-time">
                <StatusIcon />
                {record.Generals.Status}
                <p>{new Date(record.Hours.Start).toLocaleDateString()} - Hora Recogida: {new Date(record.Hours.Start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</p>
                <p>{new Date(record.Hours.End).toLocaleDateString()} - Hora Llegada: {new Date(record.Hours.End).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</p>
              </div>
              <div className="traslados-item-user-info">
                <p><BsFillPersonFill />{record.User.Name}</p>
                <p>{record.User.Document.Type}</p>
                <p>{record.User.Document.Number}</p>

              </div>
            </div>
            <div className="traslados-item-details">
              <div className="traslados-item-origin-destination">
                {ValidatedIcon()}
                <p>De: {record.Origin.Address} - {record.Origin.City} - {record.Origin.Deparment}</p>
                <p>A: {record.Destin.Address} - {record.Destin.City} - {record.Destin.Deparment}</p>
              </div>
            </div>
            <div className="traslados-item-footerD">
              <div className="traslados-item-typeD">
                <GiHealthNormal />
                {record.Medical.Eps}
              </div>
              <div className="traslados-item-footer">
                <div className="traslados-item-type">
                  <MdOutlineDirectionsCarFilled />
                  {record.Generals.Travel} - {record.Generals.Type} - {record.Mobile.Vehicle}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default TrasladosItem;

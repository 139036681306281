import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaArrowLeft } from "react-icons/fa";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import "./TrasladoDetalle.css";

const BlockTitle = ({ title, icon }) => (
  <Typography variant="h6" gutterBottom component="div" className="block-title">
    {icon} {title}
  </Typography>
);

const DataBlock = ({ data }) => (
  <Paper variant="outlined" square>
    <Box p={2}>
      <Typography variant="body1">{data}</Typography>
    </Box>
  </Paper>
);

const formatDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const TrasladoDetalle = ({ record, onBack }) => {
  const [detallesTraslado, setDetallesTraslado] = useState(
    record.details || []
  );

  useEffect(() => {
    const fetchTrasladoRecords = async () => {
      const url = `https://us-central1-mogotax-v2.cloudfunctions.net/getTransferRecord?traslados=${encodeURIComponent(
        record.id
      )}`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setDetallesTraslado(data || []);
      } catch (error) {
        console.error("Error al cargar los detalles del traslado:", error);
        setDetallesTraslado([]);
      }
    };

    if (record && record.id) {
      fetchTrasladoRecords();
    }
  }, [record]);

  return (
    <Box
      sx={{ flexGrow: 1, padding: 2 }}
      className="traslado-detalle-container"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className="header">
          <button className="back-button" onClick={onBack}>
            <FaArrowLeft />
          </button>
          <Typography variant="h4" className="traslado-id-text">
            Traslado {record.id}
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <BlockTitle
              title="Información del Usuario"
              icon={<PersonIcon className="icon-title" />}
            />
            <DataBlock data={`Nombre: ${record.User.Name} `} />
            <DataBlock data={`Documento: ${record.User.Document.Type} `} />
            <DataBlock
              data={`Núm Documento: ${record.User.Document.Number} `}
            />
            <DataBlock data={`Usuario: ${record.User.Type} `} />
            <DataBlock data={`Telefono: ${record.User.Phone} `} />

            <BlockTitle
              title="Información Médica"
              icon={<PersonIcon className="icon-title" />}
            />
            <DataBlock data={`Eps: ${record.Medical.Eps} `} />
            <DataBlock data={`Preautorización: ${record.Medical.PreAuth} `} />
            <DataBlock data={`Autorización: ${record.Medical.Auth} `} />
            <DataBlock data={`Procedimiento: ${record.Medical.Procedure} `} />
            <DataBlock data={`Diagnóstico: ${record.Medical.Diagnostic} `} />
            <DataBlock data={`Copago: ${record.Financial.Copay} `} />

            <BlockTitle
              title="Otros"
              icon={<PersonIcon className="icon-title" />}
            />
            <DataBlock
              data={`Observaciones: ${record.Generals.Observations} `}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <BlockTitle
              title="Información General"
              icon={<InfoIcon className="icon-title" />}
            />
            <DataBlock data={`Tipo Traslado: ${record.Generals.Type} `} />
            <DataBlock data={`Tipo Viaje: ${record.Generals.Travel} `} />
            <DataBlock data={`Trayecto: ${record.Generals.Course}`} />
            <DataBlock
              data={`Observaciones: ${record.Generals.Observations}`}
            />

            <BlockTitle
              title="Información De Traslado"
              icon={<InfoIcon className="icon-title" />}
            />
            <DataBlock data={`Recogida: ${formatDate(record.Hours.Start)}`} />
            <DataBlock data={`Llegada: ${formatDate(record.Hours.End)}`} />

            <BlockTitle
              title="Información Móvil"
              icon={<InfoIcon className="icon-title" />}
            />
            <DataBlock data={`Recomendado: ${record.Mobile.Recommended} `} />
            <DataBlock data={`Móvil: ${record.Mobile.Vehicle} `} />
            <DataBlock data={`Operador: ${record.Mobile.Driver}`} />
            <DataBlock data={`Tipo: ${record.Mobile.Type}`} />

            <BlockTitle
              title="Estado del Traslado"
              icon={<InfoIcon className="icon-title" />}
            />
            <DataBlock data={`Estado: ${record.Generals.Status} `} />
            <DataBlock data={`Sequimiento: ${record.Generals.Validated} `} />
          </Grid>

          <Grid item xs={12} md={4}>
            <BlockTitle
              title="Origen"
              icon={<HomeIcon className="icon-title" />}
            />
            <DataBlock data={`Lugar: ${record.Origin.Name} `} />
            <DataBlock data={`Departamento: ${record.Origin.Deparment} `} />
            <DataBlock data={`Localidad: ${record.Origin.Locality} `} />
            <DataBlock data={`Ciudad: ${record.Origin.City} `} />
            <DataBlock data={`Barrio: ${record.Origin.Neighbor} `} />
            <DataBlock data={`Dirección: ${record.Origin.Address} `} />
            <DataBlock data={`Indicaciones: ${record.Origin.Indications} `} />

            <BlockTitle
              title="Destino"
              icon={<HomeIcon className="icon-title" />}
            />
            <DataBlock data={`Lugar: ${record.Destin.Name} `} />
            <DataBlock data={`Departamento: ${record.Destin.Deparment} `} />
            <DataBlock data={`Localidad: ${record.Destin.Locality} `} />
            <DataBlock data={`Ciudad: ${record.Destin.City} `} />
            <DataBlock data={`Barrio: ${record.Destin.Neighbor} `} />
            <DataBlock data={`Dirección: ${record.Destin.Address} `} />
            <DataBlock data={`Indicaciones: ${record.Destin.Indications} `} />
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{ flexGrow: 1, padding: 2 }}
        className="traslado-detalle-container"
      >
        <Grid item xs={12} className="traslado-detalles">
          <Typography variant="h6" gutterBottom className="detalles-title">
            <HistoryIcon /> Historial de acciones
          </Typography>
          <Box className="detalles-lista">
            {detallesTraslado.map((detalle, index) => (
              <Box key={index} className="detalle-item">
                <Typography variant="body1" className="detalle-fecha">
                  {formatDate(detalle.Date)}
                </Typography>
                <Typography variant="body1" className="detalle-texto">
                  {detalle.Author}: {detalle.Action}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default TrasladoDetalle;

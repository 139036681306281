import React, { useState } from "react";
import * as XLSX from "xlsx";
import TrasladosItem from "./TrasladosItem";
import TrasladoDetalle from "./TrasladoDetalle";
import "./Traslados.css";

const Traslados = () => {
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0");

  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDayStart, setSelectedDayStart] = useState(currentDay);
  const [selectedDayEnd, setSelectedDayEnd] = useState(currentDay);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const exportToExcel = () => {
    const dataForExport = records.map((record) => ({
      CONTROL_SERVICIO: record.id,
      USUARIO_NOMBRE: record.User.Name,
      USUARIO_DOCUMENTO_TIPO: record.User.Document.Type,
      USUARIO_DOCUMENTO_NUMERO: record.User.Document.Number,
      USUARIO_TIPO: record.User.Type,
      OBSERVACIONES: record.Generals.Observations,
      ESTADO: record.Generals.Status,
      TIPO_TRASLADO: record.Generals.Type,
      VALIDADO: record.Generals.Validated,
      FECHA: new Date(record.Hours.Start).toLocaleDateString("en-GB"),
      "HORA DE RECOGIDA": new Date(record.Hours.Start).toLocaleTimeString(
        "en-GB",
        {
          hour12: false, // Formato de 24 horas
          hour: "2-digit",
          minute: "2-digit",
        }
      ),
      "HORA DE LLEGADA": new Date(record.Hours.End).toLocaleTimeString(
        "en-GB",
        {
          hour12: false, // Formato de 24 horas
          hour: "2-digit",
          minute: "2-digit",
        }
      ),
      AUTORIZACION: record.Medical.Auth,
      PREAUTORIZACION: record.Medical.PreAuth,
      DIAGNOSTICO: record.Medical.Diagnostic,
      EPS: record.Medical.Eps,
      MOTIVO: record.Medical.Procedure,
      COPAGO: record.Financial.Copay,
      VEHICULO_CONDUCTOR: record.Mobile.Driver,
      VEHICULO_TIPO: record.Mobile.Type,
      VEHICULO_MOVIL: record.Mobile.Vehicle,
      ORIGEN_NOMBRE: record.Origin.Name,
      ORIGEN_DEPARTAMENTO: record.Origin.Deparment,
      ORIGEN_CIUDAD: record.Origin.City,
      ORIGEN_LOCALIDAD: record.Origin.Locality,
      ORIGEN_BARRIO: record.Origin.Neighbor,
      ORIGEN_DIRECCION: record.Origin.Address,
      ORIGEN_INDICACIONES: record.Origin.Indications,
      DESTINO_NOMBRE: record.Destin.Name,
      DESTINO_DEPARTAMENTO: record.Destin.Deparment,
      DESTINO_CIUDAD: record.Destin.City,
      DESTINO_LOCALIDAD: record.Destin.Locality,
      DESTINO_BARRIO: record.Destin.Neighbor,
      DESTINO_DIRECCION: record.Destin.Address,
      DESTINO_INDICACIONES: record.Destin.Indications,
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Traslados");

    if (records && records.length > 0) {
      const recoleccionDesde = new Date(records[0].Hours.Start)
        .toLocaleDateString("en-GB")
        .replace(/\//g, "");
      const recoleccionHasta = new Date(records[records.length - 1].Hours.End)
        .toLocaleDateString("en-GB")
        .replace(/\//g, "");
      const fileName = `traslados_${selectedMonth}_${recoleccionDesde}-${recoleccionHasta}.xlsx`;
      XLSX.writeFile(wb, fileName);
    } else {
      alert("No hay registros para exportar.");
    }
  };

  const fetchRecords = async () => {
    setIsLoading(true);
    const url = `https://us-central1-mogotax-v2.cloudfunctions.net/getRecords?month=${selectedMonth}&dayStart=${selectedDayStart}&dayEnd=${selectedDayEnd}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const recordsData = await response.json();

      let filteredRecords = recordsData;
      if (searchQuery) {
        filteredRecords = recordsData.filter(
          (record) =>
            record.User.Name.toLowerCase().includes(
              searchQuery.toLowerCase()
            ) || record.User.Document.Number.includes(searchQuery)
        );
      }
      if (selectedStatuses.length > 0) {
        filteredRecords = filteredRecords.filter((record) =>
          selectedStatuses.includes(record.Generals.Status)
        );
      }

      setRecords(filteredRecords);
    } catch (error) {
      console.error("Error al obtener los datos: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleDayStartChange = (event) => {
    setSelectedDayStart(event.target.value);
  };

  const handleDayEndChange = (event) => {
    setSelectedDayEnd(event.target.value);
  };

  const handleTrasladoSelect = (record) => {
    setSelectedRecord(record);
  };

  const handleBackButtonClick = () => {
    setSelectedRecord(null);
  };

  const handleStatusChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedStatuses((prev) => [...prev, value]);
    } else {
      setSelectedStatuses((prev) => prev.filter((status) => status !== value));
    }
  };

  return (
    <div className="traslados-container">
      {selectedRecord ? (
        <TrasladoDetalle
          record={selectedRecord}
          onBack={handleBackButtonClick}
        />
      ) : (
        <React.Fragment>
          <header className="traslados-header">
            <div className="search-container">
              <input
                type="text"
                id="searchQuery"
                name="searchQuery"
                placeholder="Buscar por nombre o número de documento..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            {!selectedRecord && records.length > 0 && (
              <div className="export-container">
                <button onClick={exportToExcel} className="export-button">
                  Exportar a Excel
                </button>
                {/* Alerta debajo del botón */}
                <div className="export-alert-container">
                  <p className="export-alert">
                    Para descargar entrar a{" "}
                    <a
                      href="https://mogotax.online"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mogotax.online
                    </a>
                  </p>
                </div>
              </div>
            )}

            <div className="status-filters">
              {[
                "Solicitado",
                "Programado",
                "Pre-Asignado",
                "Asignado",
                "Enterado",
                "Aceptado",
                "En Camino",
                "Esperando",
                "Iniciado",
                "Fallido",
                "Cancelado",
                "Finalizado",
              ].map((status) => (
                <div key={status}>
                  <input
                    type="checkbox"
                    id={status}
                    name="status"
                    value={status}
                    checked={selectedStatuses.includes(status)}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor={status}>{status}</label>
                </div>
              ))}
            </div>

            <div className="date-selector">
              <label htmlFor="month-select">Mes:</label>
              <select
                id="month-select"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {[...Array(12).keys()].map((month) => (
                  <option
                    key={month + 1}
                    value={String(month + 1).padStart(2, "0")}
                  >
                    {month + 1}
                  </option>
                ))}
              </select>
              <label htmlFor="day-start-select">Día inicio:</label>
              <select
                id="day-start-select"
                value={selectedDayStart}
                onChange={handleDayStartChange}
              >
                {[...Array(31).keys()].map((day) => (
                  <option
                    key={day + 1}
                    value={String(day + 1).padStart(2, "0")}
                  >
                    {day + 1}
                  </option>
                ))}
              </select>
              <label htmlFor="day-end-select">Día fin:</label>
              <select
                id="day-end-select"
                value={selectedDayEnd}
                onChange={handleDayEndChange}
              >
                {[...Array(31).keys()].map((day) => (
                  <option
                    key={day + 1}
                    value={String(day + 1).padStart(2, "0")}
                  >
                    {day + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="traslados-actions">
              <button
                onClick={fetchRecords}
                className="load-button"
                disabled={isLoading}
              >
                Listar Traslados
              </button>
              <div className="traslados-count">
                <h2>Traslados Listados: {records.length}</h2>
              </div>
            </div>
          </header>
          <div className="traslados-list">
            {isLoading ? (
              <p className="loading-notification">Cargando Traslados...</p>
            ) : records.length > 0 ? (
              records.map((record) => (
                <TrasladosItem
                  key={record.id}
                  record={record}
                  onSelect={() => handleTrasladoSelect(record)}
                />
              ))
            ) : (
              <p className="no-records-notification">
                Seleccione el mes, día de inicio y día de fin, y luego haga clic
                en "Listar Traslados" para cargar los registros.
              </p>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Traslados;

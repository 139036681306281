import React from 'react';
import Traslados from './components/Traslados';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Traslados />
    </div>
  );
}


export default App;
